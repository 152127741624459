(function() {
	'use strict';
	angular
	.module('infochamApp')
	.controller('SuccesPaiementAdhesionController', SuccesPaiementAdhesionController);

	SuccesPaiementAdhesionController.$inject = ['ClickToPay','CustomProcessBonita','PaiementEnLigneWs','ReportingService','Inscription2','Certificat','DTColumnDefBuilder','$rootScope','fileUpload','Agenda','Referentiel' ,'$stateParams','$translate','$scope', 'Principal', '$state', '$http', '$compile', 'AuditsService','$filter','DTOptionsBuilder' ,'DTColumnBuilder'];

	function SuccesPaiementAdhesionController (ClickToPay,CustomProcessBonita,PaiementEnLigneWs,ReportingService,Inscription2,Certificat,DTColumnDefBuilder,$rootScope,fileUpload,Agenda,Referentiel ,$stateParams,$translate,$scope, Principal, $state, $http, $compile,  AuditsService, $filter,DTOptionsBuilder ,DTColumnBuilder) {
		$scope.PaiementClickToPay = {};
		$scope.nombreReload = false;

		$.urlParam = function(name){
			var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
			if (results==null) {
				return null;
			}
			return decodeURI(results[1]) || 0;
		}
		if($.urlParam('orderId')!=null){
			PaiementEnLigneWs.getPaiementconfig().then(function(result) {
				$scope.config = result.data;
				CustomProcessBonita.remplireCustomProcess($.urlParam('id')).then(function (result) {
					$scope.customProcess = result.data;
					$scope.oeExpediteur = $scope.customProcess.oeExpediteurDTO;
					$scope.specimenSignatures = $scope.customProcess.specimenSignatureDTOs;
					$scope.customProcess.agendaDTO.idTask = $scope.customProcess.agendaDTO.idTask;
					$scope.montant1 = result.data.demandeAdhesionDTO.typeAdhesionDTO.montant 
					$scope.paiementVirement = {};
					$scope.paiementCheque = {};
					$scope.numTmoney=0;
					$scope.numFlouss=0 ;
					$scope.RIB=0;
					$scope.verifPaiementMobile=false;
					$scope.verifPaiementEspece=false;
					$scope.paiementEspece={};
					$scope.customProcess = $scope.customProcess ;
					if($scope.customProcess !=null && $scope.customProcess.oeExpediteurDTO!=null){
						$scope.createur =$scope.customProcess.oeExpediteurDTO; 
					}
					$scope.agendaDTO = $scope.customProcess.agendaDTO;
					$scope.idAgenda=$scope.agendaDTO.id;
					$scope.demandeDossierExportDTO = $stateParams.demandeDossierExportDTO ;
					$scope.pieceJointeDTOs = $scope.customProcess.pieceJointeDTOs;
					$scope.customProcessDTO =$stateParams.customProcess ;
					$scope.expediteur =$scope.agendaDTO.oeExpediteurDTO;
					$scope.idEntity= $scope.customProcess.agendaDTO.idEntity;
					Principal.identity().then(function(account) {
						$scope.account = account;
						$scope.isAuthenticated = Principal.isAuthenticated;
						if ($scope.account == null) {
							$state.go('login');
						}
					});
					Principal.identity().then(function(account) {
				});
				$.ajax({url: $scope.config.serviceClickToPayRedirect+"?userName="+$scope.config.userName +"&password="+$scope.config.password+"&orderId="+$.urlParam('orderId')+"&language=fr", 
					success: function(result){
						var json1 = JSON.parse(result);
						$scope.PaiementClickToPay.orderId=$.urlParam('orderId');
						var datePaiement = moment(json1.depositedDate).format("YYYY-MM-DD");
						$scope.PaiementClickToPay.datePaiement=datePaiement
						$scope.PaiementClickToPay.depositedAmount=json1.paymentAmountInfo.depositedAmount;
						$scope.PaiementClickToPay.approvedAmount=json1.paymentAmountInfo.approvedAmount;
						$scope.PaiementClickToPay.paymentState=json1.paymentAmountInfo.paymentState;
						$scope.PaiementClickToPay.orderNumber=json1.orderNumber;
						$scope.PaiementClickToPay.errorCode=json1.errorCode=json1.orderStatus;
						$scope.PaiementClickToPay.errorMessage=json1.actionCodeDescription;
						$scope.PaiementClickToPay.agendaDTO=$scope.agendaDTO;
						$scope.PaiementClickToPay.mail=json1.payerData.email;
						$scope.PaiementClickToPay.numCarteBancaire=json1.cardAuthInfo.pan;
						$scope.PaiementClickToPay.approvalCode=json1.cardAuthInfo.approvalCode;
						if($scope.oeExpediteur.categorieJuridiqueDTO.valeur2=='PP'){
							$scope.montant = $scope.customProcess.demandeAdhesionDTO.typeAdhesionDTO.montantPp 
						}else{
							$scope.montant = $scope.customProcess.demandeAdhesionDTO.typeAdhesionDTO.montant 
						}
						if($scope.agendaDTO.codeStatut=='ATTPAYER'){
						 ClickToPay.addClickToPay($scope.PaiementClickToPay,$.urlParam('id'),$scope.montant).then(function (result) {
							 Inscription2.updateProcInscription($.urlParam('id'),"ATTCONFPAYER1","Payer").then(function (result) {
								 ReportingService.downloadNotePaiement($.urlParam('id'), $scope.PaiementClickToPay.orderNumber,$scope.montant).then(function (result) {
										var fileName="Quitance de paiement"
											ReportingService.downloadPdfFile(result, fileName);
										 setTimeout(function() {
											 $state.go('home', null, { reload: true } );
								    		},3000);
									});
								
							 });
							});
						}
						
					}
				});

			});
			});

		}
		$scope.noteFacture = function(){

			ReportingService.downloadNotePaiement($.urlParam('id'), $scope.PaiementClickToPay.orderNumber,$scope.montant).then(function (result) {
				var fileName="Quitance de paiement"
					ReportingService.downloadPdfFile(result, fileName);
			});



		}







	}
})();
